
import {Component, Prop} from 'vue-property-decorator';
import {required} from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';

@Component({
  components: {
    LogoComponent: () => import(
      /* webpackChunkName: "LogoComponent" */
      '@/components/Logo.component.vue'
    ),
    GoogleSSOButtonComponent: () => import(
      /* webpackChunkName: "GoogleSSOButtonComponent" */
      '@/components/Authentication/GoogleSSOButton.component.vue'
    ),
  },
  mixins: [validationMixin],
  validations: {
    username: { required },
    password: { required }
  }
})
export default class LoginComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private onPasswordResetPressed!: () => void;

  @Prop({required: true})
  private onGoogleLoginCompleted!: (idToken?: string) => void;

  @Prop({required: true})
  private onAppleLoginPressed!: (result: any) => void;

  @Prop({required: true})
  private onLoginPressed!: (payload: {username: string, password: string}) => Promise<void>;

  private username: string = '';
  private email: string = '';
  private password: string = '';

  /**
   * flag that shows and hides the chars of the password field
   */
  private hidePassword: boolean = true;

  /**
   * State bool to show the Alert above the Form
   * @private
   */
  private showAlert: boolean = false;

  private showAppleButton = true;

  private created() {
    this.showAppleButton = !/Android/i.test(navigator.userAgent);
  }

  /**
   * is called when user taps on login button, tries to login the user
   * @private
   */
  private async doLogin() {
    // Trigger validation
    this.$v.$touch();

    if (this.$v.invalid) {
      return;
    }

    await this.onLoginPressed({username: this.username, password: this.password});
  }
}
